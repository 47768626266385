<template>
    <div>
        <div class="select-file-info" v-show="isReelect === false">
            <div class="select-file-info-name">{{fileName}}</div>
            <div class="select-file-info-option">
                <el-link type="primary" @click="reelectFile">重选</el-link>
            </div>
        </div>
        <select-file ref="selectFile" v-show="isReelect === true" @onSelect="onSelect"></select-file>
    </div>
</template>

<script>
    import SelectFile from "./selectFile";
    export default {
        name: "editFile",
        components: {SelectFile},
        props:{
            fileName:{
                default:'',
                type:String
            },
        },
        data(){
            return {
                isReelect:false
            }
        },
        methods:{
            reelectFile(){
                this.isReelect = true
                console.log(this.$refs.selectFile.$el.getElementsByClassName('select-file-btn').item(0).click())
            },
            onSelect(selectFile){
                this.$emit('onSelect',selectFile)
            }
        }
    }
</script>

<style scoped>

</style>