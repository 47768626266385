<template>
    <div>
        <el-button class="select-file-btn" v-if="!isSelect" plain @click="dialogVisible = true" size="mini">添加文件</el-button>

        <div class="select-file-info" v-if="isSelect">
            <div class="select-file-info-name">{{this.selectFileName}}</div>
            <div class="select-file-info-option">
                <el-link type="primary" @click="reelectFile">重选</el-link> -
                <el-link type="primary" @click="deleteFile">删除</el-link>
            </div>
        </div>

        <el-dialog
            title="选择文件"
            :visible.sync="dialogVisible"
            width="700px"
            append-to-body
            :before-close="handleClose">
                <div slot="title">
                    我的文件
                </div>

                <div class="file-content" >
                    <div class="all-file">
                        <p class="file-tag selected">全部文件 <span class="file-count">({{this.total}})</span> </p>
                    </div>

                    <div class="file-list-content">

                        <div class="file-list" v-loading="loading">
                            <p class="search">
                                <el-input style="width: 180px" v-model="name" clearable placeholder="输入文件名"></el-input>
                                <el-button class="margin-l-sm" plain @click="search" size="mini">筛选</el-button>
                            </p>
                            <div class="file-item" v-for="file in files" v-bind:key="file.id" @click="onSelectFile(file)">
                                <span v-if="selectFileId == file.id" class="select-icon"></span>
                                <div class="file-item-top">
                                    <span class="file-icon"><img src="../img/pdf.png"/> </span>
                                    <div class="file-info">
                                        <p class="file-info-title">
                                            <el-tooltip effect="dark" :content="file.name" placement="top">
                                                <span>{{file.name}}</span>
                                            </el-tooltip>
                                        </p>
                                        <p class="file-info-size">{{parseFloat((file.size/1024)/1024).toFixed(2) + 'M'}}</p>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>

                            <div class="search">
                                <el-upload
                                    class="fl-l"
                                    ref="upload"
                                    :show-file-list="false"
                                    :action="uploadUrl"
                                    :data="uploadData"
                                    :before-upload="beforeUpload"
                                    :on-success="onSuccess"
                                    :on-error="onError"
                                    :auto-upload="true">
                                    <el-button slot="trigger" size="small" :loading="uploading" type="success">上传文件</el-button>
                                </el-upload>
                                <div class="page fl-r" >
                                    <el-pagination
                                        small
                                        background
                                        :current-page="page"
                                        :page-sizes="[10, 20]"
                                        :page-size="pageSize"
                                        layout="total, prev, pager, next "
                                        @size-change="setPageSize"
                                        @current-change="setPage"
                                        :total="total">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
                    <el-button type="primary" @click="select" size="mini">确 定</el-button>
              </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "selectFile",
        data(){
            return{
                isSelect:false,
                dialogVisible: false,
                files:[],
                name:'',
                page:1,
                pageSize:10,
                total:0,
                loading:true,
                selectFileId:0,
                selectFileName:'',
                selectFile:{},
                uploadUrl:'',
                uploadData:{},
                uploading:false
            }
        },
        methods:{
            ...mapActions('file',['getFileList']),
            ...mapActions('common',['getOssInfo']),
            ...mapActions('file',['addFile']),
            select(){
                if(this.selectFileId == 0)
                {
                    this.$message.error('请选择文件');
                    return
                }
                this.dialogVisible = false
                this.isSelect = true
                this.$emit('onSelect',this.selectFile)
            },
            handleClose(done) {
                done();
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            onSelectFile(file){
                this.selectFileId = file.id
                this.selectFileName = file.name
                this.selectFile = file
            },
            getList(){
                this.loading = true
                let data = {
                    page:this.page,
                    pageSize:this.pageSize,
                }
                if(this.name!= '')
                {
                    data.name = this.name
                }
                this.getFileList(data).then(res => {
                    this.files = res.data.list
                    this.total = res.data.total
                    this.loading = false
                })
            },
            search(){
                this.page = 1
                this.getList()
            },
            reelectFile(){
                this.dialogVisible = true
            },
            deleteFile(){
                this.isSelect = false
            },
            async beforeUpload(file){
                this.uploading = true
                let _this = this
                await this.getOssInfo('file').then(res => {
                    this.uploadUrl = res.data.host
                    _this.path = res.data.dir+'/'+file.name
                    let data = {
                        key:_this.path,
                        policy:res.data.policy,
                        OSSAccessKeyId: res.data.accessid,
                        signature:res.data.signature,
                        callback:res.data.callback,
                    }
                    _this.uploadData = data
                })
            },
            onSuccess(response, file, fileList){
                console.log(response)
                console.log(file)
                console.log(fileList)
                if(response.ret == 0){
                    this.addFile(response.data).then(res => {
                        if(res.ret == 0 && res.data.name != undefined){
                            this.$message.success('上传成功！')
                            this.getList()
                            this.uploading = false
                        }else{
                            this.$message.error('上传失败！')
                        }
                    })

                }else{
                    this.$message.error('上传失败！')
                    this.uploading = false
                }

            },
            onError(){
                this.$message.error('上传失败！')
                this.uploading = false
            }

        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>
    .select-file-info{
        padding: 20px;
        background-color: #fafafa;
        width: 160px;
    }
    .select-file-info-name{
        width: 100%;
        color: #333;
        line-height: 20px;
        font-size: 12px;
        word-break:break-all
    }
    .select-file-info-option{

    }
    .el-divider__text, .el-link{
        font-size: 12px;
    }
    .el-dialog__header{
        padding:20px;
    }
    .el-dialog__body{
        padding:0
    }
    .file-content{
        height: 528px;
        border-bottom: 1px solid #ebebeb;
        border-top: 1px solid #ebebeb;
    }
    .all-file{
        padding:20px 10px;
        float: left;
        height: 490px;
        overflow-y: scroll;
    }
    .selected{
        background: #f7f7f7;
    }
    .file-tag{
        height: 32px;
        color: #333;
        font-size: 12px;
        padding : 0 10px;
        line-height: 32px;
        cursor: pointer;
        color: #999;
    }
    .file-count{
        margin-left: 5px;
        font-size: 12px;
    }
    .search{
        width: 100%;
        flex-grow: 2;
        padding-bottom:10px
    }
    .file-list-content{
    }
    .file-list{
        padding:20px 10px;
        display: flex;
        flex-wrap:wrap;
        justify-content:space-between
    }
    .el-card__body{
        padding:10px
    }
    .select-icon{
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 30px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAadJREFUSInF1z1PFEEYAOAHEBIbf8FBQiF/gQIVExYj8Y6Y0HhGqSwEWwu1l2gvhsRGYiRobEDjBxhjZaGUYEzESKWVRqMFCeBZDAYT9nJ7y5J5y5mdeZ/dmZ13t8Vk7RquixStmMAl/IkFgNsYxWYsANzHCNZjAWAep/ArFgBeIcH3IhOd6OTnhWwAeIt+fC0ieVLiRYXLb7IDYBlH8XkvyQc7WRzmzAJ33jcHgE84gpShO/G8zLme3e0DJRYqVBd5sJo+thEAvuAYlupdMLfGvYTT3TttSYmXwyH57Mf6k7eYrGUwgEOYw/G0zpNdPCvT+4jfG6xUw2Ovd+d5AHAQD1FO60xKYb2h8pQna40nbBYA7ZhGNa3z7GE62rj7IdtkeQDQhlu4mGfw/5FlE6bFFsZxMxYAariCq7EA/+IGxuQs50UAYArnsRELADNylPMiAfAYQ5oo50UD4DUG8C0WAN7JWM73CwAr6NOgnO8nwHbyvm1MFABhGfqFr6woAMKGTIQNGgVAeDWHhFc1CoBwSI0I/yBRAITjelQ4vh2IACAUrjH8+Av5AmCiEP+iwQAAAABJRU5ErkJggg==);
    }
    .file-item{
        width: 260px;
        height: 72px;
        margin-bottom: 10px;
        border: 1px solid #ebebeb;
        cursor: pointer;
        position: relative;
        line-height: 1;
    }
    .file-icon{
        float: left;
        width: 50px;
        height: 50px;
    }
    .file-info{
        float: left;
        margin-left:10px
    }
    .file-item-top{
        padding: 12px 10px 12px 12px;
    }
    .file-info-title{
        color: #333;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
        width: 170px;
        height: 20px;
        overflow: hidden;
    }
    .file-info-size{
        color: #999;
        font-size: 12px;
    }
    .file-option{
        height: 36px;
        background: #fafafa;
        border-top:1px solid #ebebeb;

    }
    .clear{
        clear: both;
    }
</style>
